import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { firestore } from "../firebase/config";
import { doc, getDoc } from "firebase/firestore";

import Loader from "@/components/Loader";
import { CheckIcon } from "lucide-react";
import { ArrowLeft } from "lucide-react";
import { Card } from "@/components/ui/card";
import { CheckCircle } from "lucide-react";

import fetchContact from "@/scripts/fetchContact";
import fetchNeutral from "@/scripts/fetchNeutral";

const Title = ({ text }) => {
  return (
    <div className="flex items-center mt-3">
      <CheckCircle color="green" height={20} />
      <h3 className="text-md font-bold ms-2">{text}</h3>
    </div>
  );
};

function CaseDetails() {
  const { caseId } = useParams();
  const navigate = useNavigate();

  const [caseDetails, setCaseDetails] = useState();
  const [currentStep, setCurrentStep] = useState(1);

  const [phoneA, setPhoneA] = useState("");
  const [phoneB, setPhoneB] = useState("");
  const [neutral, setNeutral] = useState();

  function convertFirestoreTimestamp(seconds, nanoseconds) {
    return new Date(seconds * 1000 + nanoseconds / 1000000);
  }

  const statusMapping = {
    "Case Registered": 1,
    "Counselling Call": 2,
    "Dispute Hearing": 3,
    Settlement: 4,
    "Case Closed": 5,
  };

  const copyToClipboard = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const openLink = (link) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    const fetchCaseDetails = async () => {
      const caseDoc = doc(firestore, "cases", caseId);
      const caseSnapshot = await getDoc(caseDoc);

      if (caseSnapshot.exists()) {
        const details = caseSnapshot.data();

        setCaseDetails(details);
        setCurrentStep(statusMapping[details.current_status] || 1);

        const [numA, numB, neutralInfo] = await Promise.all([
          fetchContact(details?.party_involved?.A?.party_id),
          fetchContact(details?.party_involved?.B?.party_id),
          fetchNeutral(details?.neutral_preference),
        ]);

        setPhoneA(numA);
        setPhoneB(numB);
        setNeutral(neutralInfo);
      } else {
        alert("No such case found!");
        navigate("/dispute-registry");
      }
    };

    fetchCaseDetails();
  }, [caseId, navigate]);

  if (!caseDetails) {
    return <Loader />;
  }

  return (
    <div
      style={{
        minHeight: "calc(100vh - 72px)",
        padding: "20px",
        backgroundColor: "#F6F5F9",
      }}
    >
      <div className="flex items-center justify-between">
        <button
          className="flex items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft color="grey" />
          <p className="ml-2 text-gray-500">Back</p>
        </button>
        <p className="text-md text-grey-500">
          Registered on &nbsp;
          {convertFirestoreTimestamp(
            caseDetails.start_date.seconds,
            caseDetails.start_date.nanoseconds
          ).toLocaleString()}
        </p>
      </div>

      <div className="flex justify-between">
        {/* Title */}
        <Card className="w-[33%] mt-3 p-4">
          <h3 className="text-2xl font-bold text-grey-500">
            {caseDetails?.category ? caseDetails?.category : "Dispute"}
          </h3>
          <h3 className="text-lg font-semibold text-blue-500 mt-1">
            {`${caseDetails?.party_involved?.A?.party_name}, ${caseDetails?.party_involved?.B?.party_name}`}
          </h3>
        </Card>

        {/* Case Status */}
        <Card className="w-[66%]  mt-3 p-4 flex items-center justify-center">
          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full ${
                currentStep >= 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              {currentStep > 1 ? <CheckIcon /> : 1}
            </div>
            <span className="text-sm font-semibold mt-2 text-gray-500">
              Registered
            </span>
          </div>

          <div
            className={`flex-1 h-1 mb-5 ${
              currentStep >= 2 ? "bg-blue-500" : "bg-gray-300"
            }`}
          ></div>

          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full ${
                currentStep >= 2
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              {currentStep > 2 ? <CheckIcon /> : 2}
            </div>
            <span className="text-sm font-semibold mt-2 text-gray-500">
              Counselling Call
            </span>
          </div>

          <div
            className={`flex-1 h-1 mb-5 ${
              currentStep >= 3 ? "bg-blue-500" : "bg-gray-300"
            }`}
          ></div>

          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full ${
                currentStep >= 3
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              {currentStep > 3 ? <CheckIcon /> : 3}
            </div>
            <span className="text-sm font-semibold mt-2 text-gray-500">
              Dispute Hearing
            </span>
          </div>

          <div
            className={`flex-1 h-1 mb-5 ${
              currentStep >= 4 ? "bg-blue-500" : "bg-gray-300"
            }`}
          ></div>

          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full ${
                currentStep >= 4
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              {currentStep > 4 ? <CheckIcon /> : 4}
            </div>
            <span className="text-sm font-semibold mt-2 text-gray-500">
              Settlement
            </span>
          </div>

          <div
            className={`flex-1 h-1 mb-5 ${
              currentStep >= 5 ? "bg-blue-500" : "bg-gray-300"
            }`}
          ></div>

          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full ${
                currentStep >= 5
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              5
            </div>
            <span className="text-sm font-semibold mt-2 text-gray-500">
              Case Closed
            </span>
          </div>
        </Card>
      </div>

      <div className="flex justify-between">
        <Card className="w-[33%] mt-3 p-4">
          <h3 className="text-md font-bold text-grey-500">
            Contact Information
          </h3>

          <h3 className="text-sm font-bold text-grey-500 mt-2">
            {caseDetails?.party_involved?.A?.party_name} - Party A
          </h3>
          <p className="text-ms text-grey-500 mt-1">+91 {phoneA}</p>

          <h3 className="text-sm font-bold text-grey-500 mt-2">
            {caseDetails?.party_involved?.B?.party_name} - Party B
          </h3>
          <p className="text-ms text-grey-500 mt-1">
            {phoneB && `+91 ${phoneB},`}
            {`+91 ${caseDetails?.party_involved?.B?.party_phone}`}
          </p>

          <h3 className="text-sm font-bold text-grey-500 mt-2">
            {neutral?.name} - Neutral
          </h3>
          <p className="text-ms text-grey-500 mt-1">+91 {neutral?.phone}</p>
        </Card>

        <Card className="w-[66%] mt-3 p-4">
          <h3 className="text-md font-bold text-grey-500">Description</h3>
          <p className="text-ms text-grey-500 mt-1">
            {caseDetails?.description}
          </p>

          <h3 className="text-md font-bold text-grey-500 mt-2">Court Status</h3>
          <p className="text-md font-semibold text-blue-500 mt-1">
            {caseDetails?.court_status
              ? caseDetails?.court_status
              : "Not Applicable"}
          </p>

          <h3 className="text-md font-bold text-grey-500 mt-2">
            Resolution Method
          </h3>
          <p className="text-md font-semibold text-blue-500 mt-1">
            {caseDetails?.resolution_method
              ? caseDetails?.resolution_method
              : "Not Applicable"}
          </p>
        </Card>
      </div>

      <div className="flex justify-between">
        {/* Case Card */}
        <Card className="w-[33%] mt-3 p-4">
          <h3 className="text-md font-bold text-grey-500">Payment Plan</h3>

          {caseDetails?.payment_plan ? (
            <p className="text-ms text-grey-500 mt-1">
              <div>{caseDetails?.payment_plan?.label}</div>
              <div>Cost - {caseDetails?.payment_plan?.cost}</div>
              <div>{caseDetails?.payment_plan?.mediation}</div>
              <div>{caseDetails?.payment_plan?.support}</div>
            </p>
          ) : (
            <p className="text-ms text-grey-500 mt-1">Not Applicable</p>
          )}

          <h3 className="text-md font-bold text-grey-500 mt-2">
            {caseDetails?.party_involved?.A?.party_name} - Party A
          </h3>
          <p className="text-ms text-grey-500 mt-1">
            Counselling Fee:{" "}
            {caseDetails?.party_involved?.A?.payment?.counselling_fee?.paid ? (
              <span className="text-green-500 font-semibold">Complete</span>
            ) : (
              <span className="text-red-500 font-semibold">Pending</span>
            )}
          </p>
          <p className="text-ms text-grey-500 mt-1">
            Hearing Fee:{" "}
            {caseDetails?.party_involved?.A?.payment?.hearing_fee?.paid ? (
              <span className="text-green-500 font-semibold">Complete</span>
            ) : (
              <span className="text-red-500 font-semibold">Pending</span>
            )}
          </p>

          <h3 className="text-md font-bold text-grey-500 mt-2">
            {caseDetails?.party_involved?.B?.party_name} - Party B
          </h3>
          <p className="text-ms text-grey-500 mt-1">
            Hearing Fee:{" "}
            {caseDetails?.party_involved?.B?.payment?.hearing_fee?.paid ? (
              <span className="text-green-500 font-semibold">Complete</span>
            ) : (
              <span className="text-red-500 font-semibold">Pending</span>
            )}
          </p>
        </Card>

        {/* Status Details */}
        <Card className="w-[66%] mt-3 p-4">
          {currentStep >= 1 && <Title text="Case Registered" />}
          {currentStep >= 2 && <Title text="Counselling Call" />}
          {currentStep >= 3 && (
            <div>
              <Title text="Dispute Hearing" />
              <div className="ms-8">
                <p className="text-ms text-grey-500 mt-2">Meeting link:-</p>
                {caseDetails?.hearing_link}
                <div
                  onClick={() => copyToClipboard(caseDetails?.hearing_link)}
                  className="flex items-center justify-center h-[35px] w-[49%] bg-blue-400 rounded-md text-sm text-white mt-2 cursor-pointer font-semibold"
                >
                  Copy
                </div>
              </div>
            </div>
          )}
          {currentStep >= 4 && (
            <div>
              <Title text="Settlement Document" />
              <div className="ms-8 flex">
                <div>
                  <p className="text-ms text-grey-500 mt-2">
                    Party A Signature:-
                  </p>
                  {caseDetails?.party_involved?.A?.signature && (
                    <img
                      alt="signature"
                      src={caseDetails?.party_involved?.A?.signature}
                      className="h-[120px] w-[200px] object-cover border mt-2 mb-4"
                    />
                  )}
                </div>

                <div className="ms-6">
                  <p className="text-ms text-grey-500 mt-2">
                    Party B Signature:-
                  </p>
                  {caseDetails?.party_involved?.B?.signature && (
                    <img
                      alt="signature"
                      src={caseDetails?.party_involved?.B?.signature}
                      className="h-[120px] w-[200px] object-cover border mt-2 mb-4"
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {currentStep >= 5 && (
            <div>
              <Title text="Case Closed" />
              <div className="ms-8">
                <p className="text-ms text-grey-500 mt-2">
                  Settlement Document
                </p>
                <div
                  onClick={() => openLink(caseDetails?.document)}
                  className="flex items-center justify-center h-[35px] w-1/2 bg-blue-400 rounded-md text-white mt-3 mb-2 cursor-pointer font-semibold"
                >
                  Download
                </div>
              </div>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
}

export default CaseDetails;
