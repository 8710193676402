import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { MoreHorizontal } from "lucide-react";
import Loader from "@/components/Loader";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import fetchAllNeutrals from "@/scripts/fetchAllNeutrals";

function NeutralList() {
  const navigate = useNavigate();
  const [neutrals, setNeutrals] = useState();
  const [loading, setLoading] = useState(false);
  const [filteredNeutrals, setFilteredNeutrals] = useState([]);

  const formatPay = (amount) => {
    let x = amount.toString();
    let lastThree = x.substring(x.length - 3);
    let otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();

    if (query === "") {
      setFilteredNeutrals(neutrals);
    } else {
      const filteredPeople = neutrals.filter((person) => {
        const name = person.name ? person.name.toLowerCase() : "";
        const phone = person.phone ? person.phone.toString().toLowerCase() : "";

        return name.includes(query) || phone.includes(query);
      });

      setFilteredNeutrals(filteredPeople);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const neutralList = await fetchAllNeutrals();
      setNeutrals(neutralList);
      setFilteredNeutrals(neutralList);
      setLoading(false);
    };

    getData();
  }, []);

  if (!neutrals || loading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        minHeight: "calc(100vh - 72px)",
        padding: "20px",
        backgroundColor: "#F6F5F9",
      }}
    >
      <h2 className="text-md text-gray-600 font-bold">Neutrals List</h2>

      <Card className="p-4 mt-3">
        <h2 className="text-sm text-gray-600 font-semibold mb-2">
          Search neutral
        </h2>
        <Input
          type="text"
          placeholder="Type name, phone"
          onChange={handleSearch}
        />
      </Card>

      <Card className="p-4 mt-3">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[60px] font-bold">Sno.</TableHead>
              <TableHead className="font-bold">Name</TableHead>
              <TableHead className="font-bold">Phone</TableHead>
              <TableHead className="font-bold">Designation</TableHead>
              <TableHead className="font-bold">Experience</TableHead>
              <TableHead className="font-bold">Resolution Meode</TableHead>
              <TableHead className="font-bold">Business Approved</TableHead>
              <TableHead className="font-bold">Verified</TableHead>
              <TableHead className="font-bold">Pay Range</TableHead>
              <TableHead className="font-bold">Referral Id</TableHead>
              <TableHead className="font-bold">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredNeutrals.map((neutral, i) => (
              <TableRow key={i}>
                <TableCell className="font-medium">{i + 1}</TableCell>
                <TableCell>{neutral?.name}</TableCell>
                <TableCell className="text-[16px]">
                  {neutral?.phone && `+91 ${neutral?.phone}`}
                </TableCell>
                <TableCell>{neutral?.designation}</TableCell>
                <TableCell>{neutral?.experience}</TableCell>
                <TableCell>
                  Online {neutral?.is_physical_available && "/ Offline"}
                </TableCell>
                <TableCell>{neutral?.approved_by_business.length}</TableCell>
                <TableCell>{neutral?.is_verified ? "Yes" : "No"}</TableCell>
                <TableCell>
                  {neutral?.pay &&
                    `${formatPay(neutral?.pay?.lower_bound)} - ${formatPay(
                      neutral?.pay?.upper_bound
                    )}`}
                </TableCell>
                <TableCell>{neutral?.personal_referral_id}</TableCell>
                <TableCell>
                  <Popover>
                    <PopoverTrigger className="bg-blue-500 p-2 px-4 text-white rounded-[20px]">
                      <MoreHorizontal />
                    </PopoverTrigger>
                    <PopoverContent className="flex flex-col">
                      <Button
                        className="m-1"
                        onClick={() =>
                          navigate(`/neutral-cases/${neutral.users_id}`)
                        }
                      >
                        Cases
                      </Button>
                      <Button
                        className="m-1"
                        // onClick={() => edit(dispute?.case_id)}
                      >
                        Payouts
                      </Button>
                    </PopoverContent>
                  </Popover>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}

export default NeutralList;
