import { firestore } from "../firebase/config";
import { doc, getDoc } from "firebase/firestore";

const fetchNeutral = async (userId) => {
  try {
    // Create a reference to the document in 'users' collection using the unique userId
    const userRef = doc(firestore, "users", userId);
    // Fetch the document snapshot
    const docSnapshot = await getDoc(userRef);

    // Check if the document exists
    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      console.log("Neutral fetch from users collection");
      return userData;
    } else {
      console.log("User not found"); // Log if no user found
      return null;
    }
  } catch (error) {
    // Improved error handling with detailed error message
    console.error("Error fetching user's phone number: ", error.message);
    return null;
  }
};

export default fetchNeutral;
